import React, { useState } from 'react';

function Header() {
	const [showLinks, setShowLinks] = useState(false);

	const handleShowLinks = () => {
		setShowLinks(!showLinks);
	}

	const handleClickLinks = () => {
		setShowLinks(false);
	}

	return (
		<nav id='nav'>
			<ul className={`navbar ${showLinks ? "show-nav" : ""}`}>
				<li className='navbar__item'>
					<a className='navbar__link' href='#products' onClick={handleClickLinks}>Produits & Services</a>
				</li>	
				<li className='navbar__item'>
					<a className='navbar__link' href='#projects' onClick={handleClickLinks}>Réalisations</a>
				</li>
				<li className='navbar__item'>
					<a className='navbar__link' href='#about' onClick={handleClickLinks}>À propos</a>
				</li>
				<li className='navbar__item'>
					<a className='navbar__link' href='#contact' onClick={handleClickLinks}>Contact</a>
				</li>
			</ul>
			<button className='navbar__burger' onClick={handleShowLinks}>
				<span className='burger-bar'></span>
			</button>
		</nav>
	)
}

export default Header;