import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Header from './Header';
import ProductsList from './Product';
import ProjectsList from './Projects';
import About from './About';
import Contact from './Contact';

ReactDOM.render(
  <React.StrictMode>
		<Header />
		<About />
		<ProductsList />
		<ProjectsList />
		<Contact />
  </React.StrictMode>,
  document.getElementById('root')
);