import React, { Component } from 'react';

class About extends Component {
	render() {
		return (
			<main id='about'>
				<div className='container'>
					<p id='description'>
						Je suis <strong>Mickaël&nbsp;Gillot</strong>. <br />
						Je suis ingénieur logiciel et DevOps, développeur <em>web</em> et <em>mobile</em>.<br />
						Je propose mes services de développement et d'administration système aux entreprises, associations et particuliers.
					</p>
					<div className='avatar'>
						<img src='/me.webp' alt='Me' />
					</div>
				</div>
			</main>
		)
	}
}

class Comment extends Component {
	render() {
		return (
			<div className='comment-item'>
				<h4 className='comment-author-name'>{this.props.author}</h4>
				<h5 className='comment-author-title'>{this.props.title}</h5>
				<p className='comment-msg'>
					{this.props.msg}
				</p>
			</div>
		)
	}
}

export default About;