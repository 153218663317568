import React, { Component } from 'react';

class Project extends Component {
	render() {
		return (
			<article className='project-item'>
				<div className='tags'>
				{
					this.props.tags
						.map(t => <span key={t} className='project-tag'>{`#${t}`}</span>)
				}
				</div>
				<h1>{this.props.title}</h1>
				<p>{this.props.description}</p>
				<div className='project-links-list'>
					{
						this.props.links
							.map(l => <ProjectLink 
									key={l.title}
									title={l.title}
									href={l.href}
								/>)
					}
				</div>
			</article>
		)
	}
}

class ProjectLink extends Component {
	render() {
		return (
			<span className='project-link'>
				<a href={this.props.href}>{this.props.title}</a>
			</span>
		)
	}
}

class ProjectsList extends Component {
	render() {
		return (
			<section aria-labelledby='projects-title' id='projects'>
				<div className='container'>
					<header className='introduction'>
						<h1 id='projects-title'>Mes réalisations</h1>
						<p>Pour vous donner un aperçu de mon travail, je vous présente ici quelques projets que j'ai réalisés pour le compte de différent·e·s client·e·s</p>
					</header>	
					<div className='projects-list'>
						<Project 
							title='Congrès ISNAR-IMG 2023'
							description="Conception, intégration d'une charte graphique et développement d'un thème enfant Wordpress incluant une boutique en ligne et un formulaire d'inscription pour permettre aux congressistes de réserver leur place aux conférences et au gala organisé lors de l'édition 2023 du congrès annuel de l'ISNAR-IMG."
							tags={[
								'Wordpress',
								'Boutique',
								'Web',
								'Javascript',
							]}
							links={[
								{
									title: 'Site web',
									href: 'https://congres2023.isnar-img.com'
								}
							]}
						/>
						<Project 
							title='Application Congrès ISNAR-IMG 2022'
							description="Application, iOS & Android, à destination des congressistes pour consulter le programme, les intervenant·e·s, les partenaires et participer à une course d'orientation lors de l'édition 2022 du congrès annuel de l'ISNAR-IMG."
							tags={[
								'React\u00A0Native',
								'Mobile',
								'iOS',
								'Android'
							]}
							links={[
								{
									title: 'Play Store',
									href: 'https://play.google.com/store/apps/details?id=com.isnar_img.congres_2022'
								},
								{
									title: 'App Store',
									href: 'https://apps.apple.com/us/app/congr%C3%A8s-isnar-img-2022/id1608796853'
								}
							]}
						/>
						<Project 
							title='Cloud Personnel A. Sirac'
							description="Mise en place d'un serveur VPS et installation de services sécurisés pour usage personnel à travers plusieurs domaines (Portfolio, Blog, Stockage, etc). Formation et accompagnement pour un usage pleinement autonome."
							tags={[
								'Docker',
								'Debian',
								'VPS',
								'Cloud'
							]}
							links={[
								{
									title: 'Portfolio',
									href: 'https://alexsirac.com'
								}
							]}
						/>
					</div>
				</div>
			</section>
		)
	}
}

export default ProjectsList;