import React, { Component } from 'react';

class Product extends Component {
	render() {
		return (
			<article className='product-item'>
				<div className='img'>
					<div className='img-wrapper'>
						<img src={this.props.img} alt={this.props.name}/>
					</div>
				</div>
				<h4>{this.props.name}</h4>
				<p>{this.props.description}</p>
			</article>
		)
	}
}

class ProductsList extends Component {
	render() {
		return (
			<section aria-labelledby='products-title' id='products'>
				<div className='container'>
					<header className='introduction'>
						<h1 id='products-title'>Mes services</h1>
						<p>
							Dans le cadre de mon activité de freelance, je vous propose plusieurs prestations allant du développement web ou mobile (iOS et Android) à l'installation d'un serveur personnel et sécurisé dédié à vos applications pour des usages particuliers ou professionnels (Stockage de fichiers, Hébergement Web, Messagerie, etc).
						</p>
					</header>
					<div className='products-list'>
						<Product 
							name='Développement Web'
							description='Un site web à votre image pour vous présenter et mieux communiquer auprès de votre public.'
							img='/language_black_48dp.svg'
						/>
						<Product 
							name='Développement Mobile'
							description='Une application iOS et Android pour répondre à vos besoins.'
							img='/smartphone_black_48dp.svg'
						/>
						<Product 
							name='Cloud'
							description='Une solution technique pour héberger vos services en ligne (Mail, Stockage, SaaS, etc).'
							img='/dns_black_48dp.svg'
						/>
					</div>
				</div>
			</section>
		)
	}
}

export default ProductsList;