import React, { Component } from 'react';

class Contact extends Component {
	render() {
		return (
			<footer id='contact'>
				<div className='container'>
					<h2>Pour me suivre</h2>
					<div className='socials'>
						<a rel='me' href='https://twitter.com/MickaelGillot'>
							<div className='socials-item'>
								<div className='img-container'>
									<img src="/twitter.svg" />
								</div>
								<p>@MickaelGillot</p>
							</div>
						</a>
						<a rel="me" href="https://social.coukaratcha.fr/@couka">
							<div className='socials-item'>
								<div className='img-container'>
									<img src="/mastodon.svg" />
								</div>
								<p>@couka@social.coukaratcha.fr</p>
							</div>
						</a>
					</div>
					<p>Pour me contacter, envoyez un email à <a href='mailto:contact@mickaelgillot.xyz'>contact@mickaelgillot.xyz</a></p>
				</div>
			</footer>
		)
	}
}

export default Contact;
